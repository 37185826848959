import React from "react";
import PropTypes from "prop-types";

import { Fieldset, StyledLabel } from "./Label.styles";

const Label = ({ label, children, ...labelProps }) => (
  <Fieldset>
    <StyledLabel htmlFor={label} {...labelProps}>
      {label}
    </StyledLabel>
    {children(label)}
  </Fieldset>
);

Label.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default Label;
