import styled from "@emotion/styled";

export const Fieldset = styled.fieldset`
  border: none;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  margin-right: ${(props) => props.theme.padding.unit}px;
`;
