import styled from "@emotion/styled";

import Button from "components/Button";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  margin-top: ${(props) => props.theme.padding.unit}px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: initial;
    margin-left: 0;
    margin-right: 0;

    flex-direction: row;

    margin-top: ${(props) => props.theme.padding.unit * 2.5}px;
  }
`;

export const BackButton = styled(Button)`
  margin-bottom: 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 30px;
  }
`;

export const Image = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 359px;
    margin-right: ${(props) => props.theme.padding.unit * 2}px;
  }
`;

export const Info = styled.div`
  flex: 1;
  margin-bottom: ${(props) => props.theme.padding.unit * 2}px;
`;
