const concatenateMaterials = (materials) => {
  if (materials.length === 0) return "";

  const base = materials[0];
  return materials
    .slice(1)
    .reduce((prev, current) => `${prev}, ${current}`, base);
};

export default concatenateMaterials;
