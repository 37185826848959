import React from "react";
import PropTypes from "prop-types";

import {
  Title,
  PriceContainer,
  Price,
  Materials,
  Description,
  Form,
  LabelCss,
  DropdownCss,
  CustomColorsContainer,
} from "./ProductInfo.styles";

import concatenateMaterials from "utils/concatenateMaterials";

import Label from "components/Label";
import Dropdown from "components/Dropdown";
import Button from "components/Button";
import SeparatorBar from "components/SeparatorBar";

const ProductInfo = ({
  size,
  color,
  customColors,
  quantity,
  title,
  price,
  materials,
  description,
  quantities,
  sizes,
  colors,
  hasElegir,
  surtidoColorOption,
  customColorOption,
  handleChangeSize,
  handleChangeColor,
  handleChangeCustomColor,
  handleChangeQuantity,
  addToCart,
}) => {
  const colorOptions =
    surtidoColorOption !== undefined ? [surtidoColorOption] : colors;

  if (hasElegir) {
    colorOptions.push(customColorOption);
  }

  return (
    <>
      <Title>{title}</Title>
      <PriceContainer>
        <Price>{parseFloat(price).toFixed(2)}&euro;</Price>
      </PriceContainer>
      <Materials>{concatenateMaterials(materials)}</Materials>
      <Description>{description}</Description>

      <SeparatorBar />

      <Form>
        <Label label="Cantidad" css={LabelCss}>
          {(label) => (
            <Dropdown
              name={label}
              value={String(quantity)}
              onChange={handleChangeQuantity}
              css={DropdownCss}
            >
              {quantities.map(String)}
            </Dropdown>
          )}
        </Label>
        <Label label="Talla" css={LabelCss}>
          {(label) => (
            <Dropdown
              name={label}
              value={size}
              onChange={handleChangeSize}
              css={DropdownCss}
            >
              {sizes}
            </Dropdown>
          )}
        </Label>
        <Label label="Color" css={LabelCss}>
          {(label) => (
            <Dropdown
              name={label}
              value={color}
              onChange={handleChangeColor}
              css={DropdownCss}
            >
              {colorOptions}
            </Dropdown>
          )}
        </Label>
        {color === customColorOption ? (
          <CustomColorsContainer>
            {new Array(quantity).fill(0).map((_, index) => {
              return (
                <Label key={index} label={`Prenda ${index + 1}`} css={LabelCss}>
                  {(label) => (
                    <Dropdown
                      name={label}
                      value={customColors[index]}
                      onChange={(event) => {
                        handleChangeCustomColor(event, index);
                      }}
                      css={DropdownCss}
                    >
                      {colors.filter((color) => {
                        return color !== surtidoColorOption;
                      })}
                    </Dropdown>
                  )}
                </Label>
              );
            })}
          </CustomColorsContainer>
        ) : null}
      </Form>

      <Button onClick={addToCart}>Añadir al carrito</Button>
    </>
  );
};

ProductInfo.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  customColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  quantity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  materials: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasElegir: PropTypes.bool.isRequired,
  surtidoColorOption: PropTypes.string,
  customColorOption: PropTypes.string.isRequired,
  quantities: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleChangeSize: PropTypes.func.isRequired,
  handleChangeColor: PropTypes.func.isRequired,
  handleChangeCustomColor: PropTypes.func.isRequired,
  handleChangeQuantity: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
};
ProductInfo.defaultProps = {
  surtidoColorOption: undefined,
};

export default ProductInfo;
