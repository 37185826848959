import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Dialog } from "@reach/dialog";

import "@reach/dialog/styles.css";
import "templatesComponents/Product/Product.css";

import { customColorOption } from "@globals";
import Carousel from "components/Carousel";
import { withShoppingCart } from "components/ShoppingCart";
import GridList from "components/GridList";

import ProductInfo from "templatesComponents/Product/ProductInfo";
import {
  Container,
  BackButton,
  Image,
  Info,
} from "templatesComponents/Product/Product.styles";
import PrivateLayout from "layouts/PrivateLayout";
import { getProducItems } from "utils/getProducItems";
import { getProductDefaultQuantity } from "utils/getProductDefaultQuantity";

function getSurtidoColorOption(colors) {
  return colors.find((color) => {
    return color.toLowerCase().includes("surtido");
  });
}

class Product extends React.PureComponent {
  getInitialState = () => {
    const { sizes, colors, items } = this.props.data.contentfulProduct;

    const productItems = getProducItems(items);

    const surtidoColorOption = getSurtidoColorOption(colors);

    return {
      size: sizes[0],
      color: surtidoColorOption !== undefined ? surtidoColorOption : colors[0],
      customColors: [],
      quantity: getProductDefaultQuantity(productItems),
      showOverlay: false,
    };
  };

  state = this.getInitialState();

  showOverlay = () => {
    this.setState({ showOverlay: true });
  };
  hideOverlay = () => {
    this.setState({ showOverlay: false });
  };

  handleChangeSize = (event) => this.setState({ size: event.target.value });
  handleChangeColor = (event) => {
    const { colors } = this.props.data.contentfulProduct;

    const value = event.target.value;

    if (value === customColorOption) {
      this.setState((currentState) => {
        return {
          color: value,
          customColors: new Array(currentState.quantity).fill(colors[0]),
        };
      });
    } else {
      this.setState({ color: value, customColors: [] });
    }
  };
  handleChangeCustomColor = (event, index) => {
    this.setState((state) => {
      const updatedCustomColors = state.customColors.map((color, i) => {
        if (i === index) {
          return event.target.value;
        }
        return color;
      });
      return { ...state, customColors: updatedCustomColors };
    });
  };
  handleChangeQuantity = (event) => {
    const { colors } = this.props.data.contentfulProduct;

    const quantity = parseInt(event.target.value, 10);

    if (this.state.color === customColorOption) {
      this.setState({
        quantity,
        customColors: new Array(quantity).fill(colors[0]),
      });
    } else {
      this.setState({ quantity });
    }
  };

  addToCart = () => {
    const { size, color, customColors, quantity } = this.state;
    const { title, price, images, items } = this.props.data.contentfulProduct;

    const productItems = getProducItems(items);

    const selectedProductItem = productItems.find((productItem) => {
      return productItem.quantity === this.state.quantity;
    });

    const selectedProductItemPrice =
      selectedProductItem !== undefined ? selectedProductItem.price : price;

    const cartItem = {
      title,
      price: selectedProductItemPrice,
      quantity,
      size,
      color,
      customColors,
      image: images[0],
    };

    this.props.addToCart(cartItem);
  };

  render() {
    const { showOverlay } = this.state;
    const {
      images,
      description,
      colors,
      elegir,
      items,
      price,
      ...productInfoProps
    } = this.props.data.contentfulProduct;

    const productItems = getProducItems(items);

    const selectedProductItem = productItems.find((productItem) => {
      return productItem.quantity === this.state.quantity;
    });

    const selectedProductItemPrice =
      selectedProductItem !== undefined ? selectedProductItem.price : price;

    const productItemsQuantities =
      productItems.length > 0
        ? productItems.map((productItem) => {
            return productItem.quantity;
          })
        : [1];

    const surtidoColorOption = getSurtidoColorOption(colors);

    return (
      <PrivateLayout metaTitle={productInfoProps.title}>
        <Dialog
          isOpen={showOverlay}
          onDismiss={this.hideOverlay}
          aria-label="Images overlay"
        >
          <BackButton variation="outlined" onClick={this.hideOverlay}>
            Volver
          </BackButton>
          <GridList>
            {images.map((image, index) => (
              <Image key={`overlay-${index}`}>
                <Img fluid={image.fluid} loading="eager" />
              </Image>
            ))}
          </GridList>
          <BackButton variation="outlined" onClick={this.hideOverlay}>
            Volver
          </BackButton>
        </Dialog>
        <div>
          <Container>
            <Image style={{ cursor: "pointer" }} onClick={this.showOverlay}>
              <Carousel>
                {images.map((image, index) => (
                  <Img
                    key={`product-carousel-slide-${index}`}
                    fluid={image.fluid}
                    loading="eager"
                  />
                ))}
              </Carousel>
            </Image>
            <Info>
              <ProductInfo
                {...this.state}
                {...productInfoProps}
                price={selectedProductItemPrice}
                colors={colors}
                hasElegir={elegir === null || elegir === true}
                surtidoColorOption={surtidoColorOption}
                customColorOption={customColorOption}
                quantities={productItemsQuantities}
                description={description.description}
                handleChangeSize={this.handleChangeSize}
                handleChangeColor={this.handleChangeColor}
                handleChangeCustomColor={this.handleChangeCustomColor}
                handleChangeQuantity={this.handleChangeQuantity}
                addToCart={this.addToCart}
              />
            </Info>
          </Container>
        </div>
      </PrivateLayout>
    );
  }
}

Product.propTypes = {
  data: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
};

export default withShoppingCart(Product);

export const query = graphql`
  query ($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      title
      slug
      price
      images {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      materials
      description {
        description
      }
      items {
        title
      }
      sizes
      colors
      elegir
    }
  }
`;
